<template>
  <el-dialog
    title="新增"
    :visible="visible"
    :close-on-click-modal="false"
    :width="editForm.dialogWidth"
    @close="close"
  >
    <edit-form ref="editForm" :formConfig="editForm"></edit-form>
    <div>
      <el-button type="primary" @click="selectProduct">选择商品</el-button>
      <el-button type="primary" @click="deleteProduct">删除行</el-button>
    </div>
    <div>
      <grid-table
        ref="gridTable"
        :tableConfig="tableConfig"
        :showPagination="false"
        @selectionChange="selectionChange"
      >
      </grid-table>
    </div>
    <product-selector
      ref="productSelector"
      v-if="productSelectorDialogVisible"
      @setProduct="setProduct"
    ></product-selector>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="save">保存</el-button>
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
import { submit } from "@/api/base";
import ProductSelector from "@/views/product/base/components/ProductSelector.vue";

export default {
  name: "TradeAdd",
  props: {
    formConfig: {
      type: Object,
      require: true,
    },
  },
  components: {
    ProductSelector,
  },
  data() {
    return {
      visible: false,
      param: {},
      editForm: {},
      tableConfig: {
        columns: [
          {
            label: "商品名称",
            prop: "Title",
            type: "text",
          },
          {
            label: "货号",
            prop: "Item_no",
            type: "text",
          },
          {
            label: "规格编码",
            prop: "SkuId",
            type: "text",
          },
          {
            label: "商品规格",
            prop: "SkuPropertiesName",
            type: "text",
          },
          {
            label: "成本价",
            prop: "TotalCost",
            type: "text",
          },
          {
            label: "单品成本价",
            prop: "ProductCost",
            type: "text",
          },
          {
            label: "价格",
            prop: "Price",
            type: "input-decimal",
          },
          {
            label: "购买数量",
            prop: "ProNum",
            type: "input-int",
          },
          {
            label: "金额",
            prop: "TotalFee",
            type: "text",
          },
        ],
      },
      selectRows: [],
      productSelectorDialogVisible: false,
    };
  },
  methods: {
    init() {
      this.$nextTick(() => {
        this.editForm = {};
        this.editForm = this.formConfig
          ? {
              ...(this.formConfig || {}),
              data: { ...(this.formConfig.data || {}) },
              items: [...(this.formConfig.items || [])],
            }
          : {};
        this.fetchData();
      });
      this.visible = true;
    },
    async fetchData() {
      const { data } = await submit(this.editForm.url, this.param);
      this.editForm.data = { ...this.editForm.data, ...data };
      const { items, rules } = this.getFormRules(
        this.editForm.items,
        data.InitData
      );
      this.$set(this.editForm, "items", items);
      this.$set(this.editForm, "rules", rules);
      this.$nextTick(() => {
        this.$refs.editForm.clearValidate();
      });
    },
    selectionChange(selection) {
      this.selectRows = selection;
    },
    selectProduct() {
      this.productSelectorDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.productSelector.init();
      });
    },
    setProduct(rows) {
      let datas = [...this.$refs.gridTable.tableData];
      rows.forEach((row) => {
        if (!datas.find((data) => data.SpecID == row.SpecID)) {
          let data = {
            ProductID: row.ProductID,
            SpecID: row.SpecID,
            Title: row.PName,
            PicPath: row.DefaultImage,
            Item_no: row.ItemNo,
            SkuId: row.Sku,
            SkuPropertiesName: row.SpecName,
            TotalCost: row.Cost,
            ProductCost: row.Cost,
            Price: row.UserPrice,
            ProNum: 1,
            TotalFee: row.UserPrice,
          };
          datas.push(data);
        }
      });
      this.$refs.gridTable.setData(datas);
    },
    deleteProduct() {
      if (this.selectRows.length == 0) {
        this.$baseMessage("请选择行", "error");
        return;
      }
      this.$baseConfirm("确定选中行要删除吗？", null, () => {
        let datas = [...this.$refs.gridTable.tableData];
        this.selectRows.forEach((row) => {
          datas.forEach((data, index) => {
            if (data.SpecID == row.SpecID) {
              datas.splice(index, 1);
            }
          });
        });
        this.$refs.gridTable.setData(datas);
      });
    },
    save() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          let param = { ...this.editForm.data };
          param.ReceiverState =
            param.ReceiverArea.length > 0 ? param.ReceiverArea[0] : "";
          param.ReceiverCity =
            param.ReceiverArea.length > 1 ? param.ReceiverArea[1] : "";
          param.ReceiverDistrict =
            param.ReceiverArea.length > 2 ? param.ReceiverArea[2] : "";
          param.OrderDetails = this.$refs.gridTable.tableData;
          await submit(this.editForm.saveUrl, param);
          this.$baseMessage("保存成功", "success");
          this.$emit("handleQuery");
          this.close();
        } else {
          return false;
        }
      });
    },
    close() {
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
